import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/lib/annual-report/post-layout/post-layout.tsx";
import { RadialChartBlock } from "../../../../../lib/annual-report/radial-chart-block/radial-chart-block";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const AuthorBlock = makeShortcode("AuthorBlock");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <AuthorBlock title={"Pam York"} subtitle={"Primary School Teacher Librarian"} mdxType="AuthorBlock">
      <p>{`  `}<span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "546px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/d76f2aef9624dd068ef82aadc0fd0599/f066e/02.jpg",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "150%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAeABQDASIAAhEBAxEB/8QAGQAAAgMBAAAAAAAAAAAAAAAAAAUCAwQB/8QAFgEBAQEAAAAAAAAAAAAAAAAAAgMB/9oADAMBAAIQAxAAAAFHppb4kp0oJ71d0qVkBD//xAAaEAADAAMBAAAAAAAAAAAAAAAAAQIDERIx/9oACAEBAAEFAkVgqYJK1wRocvh+iy2kf//EABcRAAMBAAAAAAAAAAAAAAAAAAECEBH/2gAIAQMBAT8BVdgM/8QAGBEAAwEBAAAAAAAAAAAAAAAAAAERAhD/2gAIAQIBAT8Bepxop//EABoQAAICAwAAAAAAAAAAAAAAABARAAESITH/2gAIAQEABj8CmRunfBuIpj//xAAcEAACAgMBAQAAAAAAAAAAAAABEQAhEDFBUWH/2gAIAQEAAT8hBlRkJYG34bhExC24d3ONCewsxoQ7vCmr9x//2gAMAwEAAgADAAAAEEfgPv/EABcRAQEBAQAAAAAAAAAAAAAAAAEAESH/2gAIAQMBAT8QAazyMMZ7f//EABcRAQEBAQAAAAAAAAAAAAAAAAEAESH/2gAIAQIBAT8QZYR02ZdIQZf/xAAcEAEAAwEAAwEAAAAAAAAAAAABABExIRBRcbH/2gAIAQEAAT8QUhrB5p6D0lQW14SPZKbIhY6pkCI0bErbAJ5HwUBB/LjtCkafC56iilp8Yz//2Q==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "02",
              "title": "02",
              "src": "/static/d76f2aef9624dd068ef82aadc0fd0599/f066e/02.jpg",
              "srcSet": ["/static/d76f2aef9624dd068ef82aadc0fd0599/f93b5/02.jpg 300w", "/static/d76f2aef9624dd068ef82aadc0fd0599/f066e/02.jpg 546w"],
              "sizes": "(max-width: 546px) 100vw, 546px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
  `}</a>{`
    `}</span></p>
    </AuthorBlock>
    <AuthorBlock title={"Polina Spencer"} subtitle={"School Teacher Librarian"} mdxType="AuthorBlock">
      <p>{`  `}<span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "546px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/e81c090b7bdb8b2f43c69ba94334a8ce/f066e/01.jpg",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "150%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAeABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAQFAwL/xAAYAQACAwAAAAAAAAAAAAAAAAABAwACBP/aAAwDAQACEAMQAAABjPp14YhqMXs+s9TYHIGf/8QAHRABAAEEAwEAAAAAAAAAAAAAAgEAAxESBCIyE//aAAgBAQABBQKIzL4+ooelJm0zhWx0tDr8zQeIHjav/8QAGBEBAAMBAAAAAAAAAAAAAAAAAQACEBL/2gAIAQMBAT8BoCZQJyT/xAAYEQEBAQEBAAAAAAAAAAAAAAABACECEf/aAAgBAgEBPwHtRjZ28v/EABwQAAEEAwEAAAAAAAAAAAAAABAAARExEiFhgf/aAAgBAQAGPwKFvxzTcGTqRR//xAAcEAACAwADAQAAAAAAAAAAAAAAAREhMUFRcZH/2gAIAQEAAT8higmbTwdEGAwtdVxzK2xbagXpT4G/Z+i4H4GhWvS7c9n/2gAMAwEAAgADAAAAEMwoMP/EABcRAQEBAQAAAAAAAAAAAAAAAAEAEBH/2gAIAQMBAT8QcLIjyMAYf//EABgRAQEBAQEAAAAAAAAAAAAAAAEAESFR/9oACAECAQE/EOYZAG6a2PL/xAAfEAEBAAICAQUAAAAAAAAAAAABEQAhMUFhUXGhweH/2gAIAQEAAT8QcTlxEs3UF4cUKOkxxOZ165EQ6Epf3IUkAJiuVdauvbECD2lYfTjt2hFIuKLGFDofJhDJb8sFC2Rn/9k=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "01",
              "title": "01",
              "src": "/static/e81c090b7bdb8b2f43c69ba94334a8ce/f066e/01.jpg",
              "srcSet": ["/static/e81c090b7bdb8b2f43c69ba94334a8ce/f93b5/01.jpg 300w", "/static/e81c090b7bdb8b2f43c69ba94334a8ce/f066e/01.jpg 546w"],
              "sizes": "(max-width: 546px) 100vw, 546px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
  `}</a>{`
    `}</span></p>
    </AuthorBlock>
    <blockquote>
      <p parentName="blockquote">{`For the school year 2019 / 2020, the PSI library team has focused on increasing circulation through making the fiction collection more visually accessible through ‘genrefication’, which allows patrons to discover more diverse titles and authors of interest.`}</p>
    </blockquote>
    <p>{`The Primary library genrefication project began in September, and with the help of a number of parent volunteers, was completed in March. The Secondary library genrefication project is ongoing, with genres being allocated to all fiction titles in the Destiny catalogue.  We hope that when the campus reopens, we will see increased circulation as a result of the new fiction section organisation.`}</p>
    <p>{`We continue weeding outdated, unpopular, damaged and duplicated copies of titles and purchasing new resources in order to streamline and update the collection.`}</p>
    <p>{`Circulation for the period between 1 April 2019 and 31 March 2020 was 17,286,`}<br parentName="p"></br>{`
`}{`which is a surprising increase for the same period last year due to the closure of the campus in early March.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1192px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/f3e4ac0c3dbb258e7f2e1ee2095f72d8/f213e/chart-1.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "62%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAACXBIWXMAABYlAAAWJQFJUiTwAAACMUlEQVQoz32Sa0/UUBCG+ZsS3IgEvEDwpxgUEBfcS4JEJBqN8kmILARYNEQTjYSLBLm6l7Z76Wl72tM+5pwurvDBk0znvO3MO+/MtCdJEv49GrdaLSzLolar4TgOlUrFYH2v1y1jlmWb2Gvp9OiH7/s0m03iODamseu6xjzPo91u47oCIQTC84zX75WKjekc7XUBQ3hJ1NF4VfFVAai4e4+NwoRaK2b3XHUVSilN1aQTkCQxylRN8GRCHCeG+utRxEJZsnOqKP2IKO9HvNsOmV2TDDwVJuavwiiKUDoxSag2FTtnEZt7EQ8XA74fRyx9C8l9DEyi9ndmBNnlgKFpQWFF0jfeIdSz0/MIgoDLCb/fDk3i3LrkxiPB8zVJZiJNHMl7FEup1/hBMcX6uyG0bdu0G6uQ45pi+zDizWdJZlKY9gazgvkNyVBWkF+R3M91CHMe+ZWA0YJHoSS5eUmo25VhBARGma70akvS/0TwclMyMCV4sZESa4LhnKBYCq4RBl2FZimhMoSLXyL6xj1efwrpn/JYKOvWfeY3QwazPoVSyHDOp7gaMpL3yZdCRgspzkx66Zb1Vs0SSXi75dI75jC/7pJ57DC35nJrwmF21eX2ZIOZJZe72QbPltvcm24w/aHN8EyKe8dsSFRKmJjlxlStNvvHDhe1JgcnXX9ebbD3q87pb4eDE4uLWovDU4ezSoOfJzbn1Sa7R3WUUmnLWt31H/jqiWk1bBqOTST9/0b+Ae0wZUS2pQmHAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "chart 1",
            "title": "chart 1",
            "src": "/static/f3e4ac0c3dbb258e7f2e1ee2095f72d8/f213e/chart-1.png",
            "srcSet": ["/static/f3e4ac0c3dbb258e7f2e1ee2095f72d8/5a46d/chart-1.png 300w", "/static/f3e4ac0c3dbb258e7f2e1ee2095f72d8/0a47e/chart-1.png 600w", "/static/f3e4ac0c3dbb258e7f2e1ee2095f72d8/f213e/chart-1.png 1192w"],
            "sizes": "(max-width: 1192px) 100vw, 1192px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`This school year, 1,931 books have been weeded from the library using the guidelines in the Library Policies and Procedures Handbook. Our current collection consists of 24,813 items, representing 21,410 individual titles, which is an increase of around 1,000 titles from last school year. The value of this collection is estimated at $282,277.23, which contains a valid approximation of the cost for copies that do not contain price information. Our annual TitleWise™ collection analysis recognised 99.3% of call numbers and 94.6% publication years. The average year of publication of the collection is 2005 with 2,797 items being published since 2015. The collection is balanced with 49% of the collection being fiction titles and 51% non-fiction titles.`}</p>
    <RadialChartBlock title={'Literary vs. informational text'} data={[{
      name: '%, Fiction',
      value: 49,
      color: '#F7C454'
    }, {
      name: '%, Nonfiction',
      value: 51
    }]} mdxType="RadialChartBlock" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      